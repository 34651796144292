/** @jsx jsx */
import { Box, useThemeUI, jsx } from 'theme-ui';
import React from 'react';
import NewsroomCard from './NewsroomCard';
import CarouselTemplate from '../../../../carouselTemplate';
import { filterByTranslations } from '../../../../../../scripts/createPagesUtils/createPagesHelperFunctions';

type NewsroomCarouselProps = { data: Array<Record<string, unknown>> };

const NewsroomCarousel: React.FC<NewsroomCarouselProps> = props => {
  const { theme } = useThemeUI();

  const filteredData = filterByTranslations(props?.data ?? []);
  const numberOfCards = filteredData.length || 0;
  const getSlidesPerScreen = maxNumberOfCards =>
    numberOfCards < maxNumberOfCards ? numberOfCards : maxNumberOfCards;
  const slidesToShowDesktop = getSlidesPerScreen(3);
  const slidesToShowTablet = getSlidesPerScreen(2);

  // mx trickery is a hacky solution to replicate grid-gap
  return (
    <Box sx={{ mb: [6, 6, 5], mt: ['-20px'], mx: [null, -theme.space[5] / 4] }}>
      <CarouselTemplate
        topMargin="NONE"
        slidesToShowDesktop={slidesToShowDesktop}
        slidesToShowTablet={slidesToShowTablet}
        noContainerLeftRightPadding={true}
      >
        {filteredData.map((item, i) => (
          <Box key={i} sx={{ px: [null, theme.space[5] / 4] }}>
            <NewsroomCard newsItem={item} variant="plain" />
          </Box>
        ))}
      </CarouselTemplate>
    </Box>
  );
};

export default NewsroomCarousel;
