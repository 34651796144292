import { isRedirected } from '@sprinklr/shared-lib/utils';
import { resolveLocale } from '@sprinklr/shared-lib/utils';
import { getTranslationContextVariable } from '../../../shared-lib/utils/languageData';
import { getPageSlug } from '@sprinklr/shared-lib/utils/featurePage';

export const filterOutPagesWithRedirects = ({ slug, nodeLocale, productCategory }, allRedirects, categorySlug) => {
  let fullSlug = getPageSlug({ categorySlug, slug, productCategory });

  return !isRedirected(fullSlug, nodeLocale, allRedirects);
};

export const localizePath = (nodeLocale: string, path: string) => {
  if (!nodeLocale || !path) return;
  const localePathShort = resolveLocale(nodeLocale);
  let localizedPath = `/${localePathShort}/${path}`.replace(/\/\/+/g, '/');
  localizedPath = localizedPath.replace(/\/?$/, '/');
  return localizedPath;
};

export const filterByTranslations = data => {
  return data.filter(({ nodeLocale, translations }) => {
    if (translations !== null) return translations?.languagesToRenderIn?.includes(getTranslationContextVariable(nodeLocale));
    else return false;
  });
};

export const createPagesFilter = (data, newCustomerStoriesWithNoPage, allRedirects, filterPagesOnTranslations) => {
  const filteredData = data
    .filter(node => {
      const customerStoryTemplateId = node?.pageContent?.templates?.find(template => !!template?.id)?.id;
      if (!customerStoryTemplateId || !newCustomerStoriesWithNoPage?.includes(customerStoryTemplateId)) return true;
      return false;
    })
    .filter(node => filterOutPagesWithRedirects(node, allRedirects, node.categorySlug || ''));
  if (!!filterPagesOnTranslations) {
    return filteredData.filter(({ nodeLocale, translations }) => {
      if (translations !== null) return translations?.languagesToRenderIn?.includes(getTranslationContextVariable(nodeLocale));
      else return false;
    });
  } else return filteredData;
};
