/** @jsx jsx */
import { jsx, Flex, Box, Themed } from 'theme-ui';
import { useResponsiveValue } from '@theme-ui/match-media';
import { Container } from '@sprinklr/shared-lib';
import { useContainerPadding } from '@sprinklr/shared-lib/hooks';
import { ContainerPadding } from '@sprinklr/shared-lib/@types/types';
import NewsroomGrid from './newsroom/components/NewsroomGrid';
import NewsroomSectionTitle from './newsroom/components/newsroomSectionTitle';
import NewsroomCarousel from './newsroom/components/NewsroomCarousel';
import { Trans, useI18next } from 'gatsby-plugin-react-i18next';
import NoLocaleContentTemplate from '../../customTemplate/noLocaleContent/NoLocaleContent';

import { NewsroomLandingPageGradient } from '@sprinklrjs/website-components/components/gradient/newsroomLandingPage/NewsroomLandingPageGradient';

const NewsItems: React.FC<{
  title: string;
  newsType: string;
  newsData?: any;
}> = ({ title, newsType, newsData }) => {
  const renderSectionLayout = () => {
    switch (newsType) {
      case 'HIGHLIGHTS':
        return <NewsroomCarousel data={newsData} />;
      case 'MEDIA_COVERAGE':
        const displayMediaData = newsData?.slice(
          0,
          useResponsiveValue([3, 3, 3, 4]),
        );
        return (
          <NewsroomGrid
            data={displayMediaData}
            isFixedLength={true}
            cardVariant="media"
          />
        );
      case 'PRESS_RELEASES':
        const displayPressReleaseData = newsData?.slice(
          0,
          useResponsiveValue([4, 4, 4, 3]),
        );
        return (
          <NewsroomGrid
            data={displayPressReleaseData}
            isFixedLength={true}
            cardVariant="plain"
          />
        );
      default:
        return (
          <NewsroomGrid
            data={newsData?.slice(0, useResponsiveValue([3, 3, 4, 4]))}
            isFixedLength={true}
            cardVariant="plain"
          />
        );
    }
  };
  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ mb: [4, 5] }}>
        <NewsroomSectionTitle
          title={title}
          readmoreLink={'/newsroom/all-news/'}
        />
        {renderSectionLayout()}
      </Box>
    </Box>
  );
};

const NewsroomTemplate: React.FC<{
  padding: ContainerPadding;
  localizedNews: any;
}> = ({ padding, localizedNews }) => {
  const [pt, pb] = useContainerPadding(padding);
  const { t } = useI18next();

  const MailLink = () => (
    <Box
      as="a"
      href="mailto:pr@sprinklr.com"
      target="__blank"
      sx={{
        color: 'inherit',
        textDecoration: 'none',
        ':hover': {
          color: '#185ad2',
        },
      }}
    >
      PR@Sprinklr.com
    </Box>
  );

  const mediaCoverage = localizedNews
    ?.filter(item => item.type === 'FEATURED_NEWS')
    .sort((a, b) => b.publishedOn.localeCompare(a.publishedOn));
  const pressReleases = localizedNews
    ?.filter(item => item.type === 'PRESS_RELEASE')
    .sort((a, b) => b.publishedOn.localeCompare(a.publishedOn));

  return !localizedNews?.length ? (
    <Box>
      <NoLocaleContentTemplate padding={{ top: 'LARGE', bottom: 'LARGE' }} />
    </Box>
  ) : (
    <NewsroomLandingPageGradient>
      <Container containerSx={{ pt, pb }}>
        <Flex
          sx={{
            justifyContent: 'center',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Themed.h1 sx={{ mb: [5] }}>{t('Sprinklr Newsroom')}</Themed.h1>
          <Themed.p sx={{ mb: [5] }}>
            <Trans i18nKey="prMailLine">
              For press inquiries, please contact <MailLink />
            </Trans>
          </Themed.p>

          {!!pressReleases.length && (
            <NewsItems
              title={t('Press Releases')}
              newsType={'PRESS_RELEASES'}
              newsData={pressReleases}
            />
          )}
          {!!mediaCoverage.length && (
            <NewsItems
              title={t('Media Coverage')}
              newsType={'MEDIA_COVERAGE'}
              newsData={mediaCoverage}
            />
          )}
        </Flex>
      </Container>
    </NewsroomLandingPageGradient>
  );
};

export default NewsroomTemplate;
