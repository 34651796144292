/** @jsx jsx */
import { Box, Flex, jsx, Themed } from 'theme-ui';
import React from 'react';
import { Link } from '@sprinklr/shared-lib';
import { useI18next } from 'gatsby-plugin-react-i18next';

type NewsroomSectionTitleProps = {
  title: string;
  readmoreLink?: string;
};

const NewsroomSectionTitle: React.FC<NewsroomSectionTitleProps> = ({
  title,
  readmoreLink,
}) => {
  const { t } = useI18next();
  return (
    <Flex
      sx={{
        alignItems: 'center',
        justifyContent: 'space-between',
        mb: [4, 5],
        pb: [3, 0],
      }}
    >
      <Themed.h2 as="h2" sx={{ m: 0, fontSize: [6] }}>
        {title}
      </Themed.h2>
      {readmoreLink ? (
        <Link styledLinkSx={{ fontSize: '18px' }} href={readmoreLink}>
          {t('More News')}
        </Link>
      ) : null}
    </Flex>
  );
};

export default NewsroomSectionTitle;
